.controlComponent {
  height: 54px;
}

.multiSelect {
  &--small {
    .title {
      font-size: 1.25rem;
      line-height: 1;
    }

    .controlComponent {
      height: 60px;
      padding: 4px 16px;
    }

    .select__single-value {
      font-size: 0.825rem;
      line-height: 1;
      font-weight: 400;
      margin: 6px 0 0;
    }
  }
}
.select__indicator {
  display: none !important;
  * {
    display: none !important;
  }
}

.select__option {
  &:hover {
    svg {
      opacity: 0.2;
    }
  }

  &--selected {
    &:hover {
      svg {
        opacity: 1;
      }
    }
  }
}

.select--rwd {
  @screen md-max {
    .controlComponent {
      @apply border-t-0 border-l-0 border-r-0 border-gray-100 rounded-none;

      .fa-chevron-down {
        @apply transform -rotate-90;
      }
    }
  }
}

.select__menu {
  @screen md-max {
    position: fixed !important;
    top: 55px !important;
    height: calc(100dvh - 130px);
    border-radius: 0px !important;
    left: 0px;
    right: 0px;
    border: 0 !important;
    z-index: 30 !important;
    width: 100%;

    .listMenu-wrapper {
      overflow: scroll;
      height: calc(100dvh - 190px);
    }
  }
}

.multiSelect--small {
  .select__menu-list {
    padding: 0 13px;
  }
  @screen md-max {
    .select__menu {
      z-index: 60 !important;
    }
  }
}

.select__menu-list {
  @screen md-max {
    max-height: calc(100vh - 115px) !important;
  }
}
