@use 'styles/unit' as *;

.navMain {
  //mx-auto lg:flex lg:items-center
  margin: 0 auto;

  @screen lg {
    display: flex;
    align-items: center;
  }
  a {
    padding: rem-calc(8) rem-calc(7);

    @screen lg {
      font-size: rem-calc(15);
      line-height: rem-calc(22);
    }
  }

  li {
    margin: rem-calc(24) 0 0 0;

    @screen lg {
      margin: 0;
    }
  }

  &__link {
    // cursor-pointer hover:text-primary rounded-lg transition-colors duration-300 top-nav
    cursor: pointer;
    border-radius: rem-calc(8);
    transition: 0.3s;

    &:hover {
      color: #ec602b;

      .navMain__label {
        animation-name: shake;
        animation-duration: 0.3s;
      }
    }
  }

  &__employersButton {
    height: rem-calc(46);
    font-size: rem-calc(16);
    line-height: rem-calc(22);
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    background-color: #ec602b;
    border-radius: rem-calc(30);
    color: #ffffff;

    @screen sm {
      display: none;
    }
  }

  &__label {
    margin-left: rem-calc(5);
    padding: rem-calc(4) rem-calc(4);
    border-radius: rem-calc(4);
    letter-spacing: 0.04em;
    font-size: rem-calc(10);
    line-height: rem-calc(14);
    display: block;

    svg {
      width: rem-calc(17);

      @screen sm {
        margin-right: rem-calc(7);
      }
    }
  }
}

@keyframes shake {
  0% {
    transform: rotate(6deg);
  }
  25% {
    transform: rotate(9deg);
  }
  75% {
    transform: rotate(3deg);
  }
  100% {
    transform: rotate(6deg);
  }
}
