.content {
  @apply font-light leading-normal text-md;

  strong {
    @apply font-medium;
  }

  a:hover {
    cursor: pointer;
    --tw-bg-opacity: 0.7;
  }
  a {
    --tw-bg-opacity: 1;
    color: rgba(114, 110, 255, var(--tw-bg-opacity));
    text-decoration: none;
  }
  ol {
    list-style-type: decimal;
    list-style-position: inside;
  }
  ul {
    list-style: disc;
    padding-left: 15px;
    padding-bottom: 30px;
  }
  li {
    margin-bottom: 10px;
  }
  .ql-indent-1 {
    margin-left: 35px;
  }
  ol ::marker {
    font-weight: 500;
  }
  code {
    word-break: break-word;
    white-space: pre-wrap;
    font-size: 16px;
  }

  h1,
  h2,
  h3 {
    margin-bottom: 15px;
  }

  h2 {
    margin-top: 60px;
    line-height: 1.2;
  }

  h3 {
    margin-top: 30px;
    font-weight: 600;
  }
  p {
    margin-bottom: 30px;
  }
  p.center {
    text-align: center;
    img {
      margin: 0 auto;
    }
  }
  img {
    margin: 30px 0;
  }
  img.center,
  video.center {
    margin: 0 auto;
  }

  .img-responsive {
    max-width: 100%;
    height: auto;
    margin: 0 auto;
  }
  blockquote {
    border-left: 5px #e5e5e5 solid;
    padding-left: 15px;
  }
  pre {
    white-space: pre-wrap;
    overflow-x: scroll;
  }
  iframe {
    max-width: 100%;
  }
  .break-anywhere {
    line-break: anywhere;
  }
  .btn.btn-primary.btn-lg.btn-shadow {
    border: 0 solid #ccc;
    text-decoration: inherit;
    position: relative;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    border-radius: 9999px;
    border-width: 3px;
    --tw-border-opacity: 1;
    border-color: rgb(236 96 43 / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgb(236 96 43 / var(--tw-bg-opacity));
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    font-weight: 400;
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
    transition-property: color, background-color, border-color,
      text-decoration-color, fill, stroke, opacity, box-shadow, transform,
      filter, backdrop-filter, -webkit-text-decoration-color,
      -webkit-backdrop-filter;
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    font-size: 1.5rem;
    line-height: 2rem;
    margin-bottom: 0;
    height: 4rem;
    width: auto;
  }

  @media (max-width: 768px) {
    h2 {
      margin-top: 40px;
      font-size: 2rem;
    }
    h3 {
      margin-top: 25px;
      margin-bottom: 10px;
      font-size: 1.75rem;
    }
    p {
      margin-bottm: 20px;
    }
  }
}
