.menuHamburger {
  @apply block w-9 ml-6;

  span {
    @apply block rounded-lg bg-black my-2 h-1;
  }

  @screen xl {
    @apply hidden;
  }

  &__expanded {
    position: relative;
    width: 29px;
    height: 29px;

    span {
      position: absolute;
      margin: 0;

      &:first-child {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: 2px solid #000;
        background-color: #fff;
        top: 0;
      }

      &:nth-child(2),
      &:nth-child(3) {
        width: 10px;
        height: 2px;
        top: calc(50% - 1px);
        left: calc(50% - 5px);
      }

      &:nth-child(2) {
        transform: rotate(45deg);
      }

      &:nth-child(3) {
        transform: rotate(-45deg);
      }
    }
  }
}
