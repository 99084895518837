@use 'styles/unit' as *;

.menu {
  &__wrapper {
    @apply flex-1 left-0 z-20 h-screen  absolute transform -translate-x-full transition duration-300 pl-6 lg:pl-0 pr-6 lg:pr-0;
    top: rem-calc(70);

    @screen lg {
      @apply flex translate-x-0 static h-auto;
    }
  }

  &--visible {
    @apply bg-white   translate-x-0;
    width: 86%;
  }

  &__containerWrapper {
    // max-width: rem-calc(1200);
    margin: 0 auto;
    display: flex;
    align-items: center;
  }

  &__employersButton {
    height: rem-calc(46);
    font-size: rem-calc(16);
    line-height: rem-calc(22);
    width: auto;
    align-items: center;
    justify-content: center;
    display: none;
    border-radius: rem-calc(30);
    padding: rem-calc(12) rem-calc(24);
    margin-right: rem-calc(16);
    transition: 0.3s;

    border: 1px solid #aaaaaa;
    color: #040000;

    @screen sm {
      display: flex;
    }

    &:hover {
      background-color: #efefef;
    }
  }

  &__langChangerWrapper {
    margin-left: rem-calc(24);
    display: none;

    @screen lg {
      display: block;
    }
  }

  &__langChangerWrapperMobile {
    margin-right: rem-calc(24);
    display: block;

    @screen lg {
      display: none;
    }
  }
}

.header-primary {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 50;
  background-color: #fff;

  &:before {
    content: '';
    background: rgba(#000, 0.33);
    width: 100vw;
    position: fixed;
    left: 0;
    top: 75px;
    opacity: 0;
    height: calc(100vh - 54px);
    display: block;
    pointer-events: none;
    transition: opacity 0.3s;
  }

  &__expanded {
    &::before {
      opacity: 1;
    }
  }
}
