@import 'nprogress/nprogress.css';

#nprogress .bar {
  background: #ec602b;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  scroll-behavior: auto !important;
}
:root {
  font-family: var(--font-montserrat);
}
@layer base {
  html {
    font-size: 75%;
    scroll-behavior: smooth;
  }

  .swiper {
    overflow: visible !important;
  }

  svg {
    display: inline;
  }

  .video-container {
    overflow: hidden;
    position: relative;
    width: 100%;
  }

  .video-container::after {
    padding-top: 56.25%;
    display: block;
    content: '';
  }

  .video-container iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80%;
    height: 80%;
    transform: translate(-50%, -50%);
  }

  @keyframes rocking {
    0% {
      transform: rotate(0) translateY(0);
    }
    25% {
      transform: rotate(2deg) translateY(-20px);
    }
    50% {
      transform: rotate(0) translateY(0);
    }
    75% {
      transform: rotate(-2deg) translateY(-20px);
    }
    100% {
      transform: rotate(0) translateY(0);
    }
  }

  .brain {
    animation: rocking 10s ease-in-out infinite;
    position: relative;
  }

  @keyframes slide-up {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0%);
    }
  }

  .cookie-bar {
    animation: slide-up 0.2s ease-in-out 1;
  }

  html {
    padding: 0 !important;
  }
  body {
    @apply font-medium subpixel-antialiased text-base;
    font-size: 1.2rem;
    padding-top: 60px;

    @screen md {
      padding-top: 75px;
    }
  }

  button {
    @apply font-medium;
  }

  .opaque {
    opacity: 1 !important;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  }

  h1 {
    @apply text-4xl font-bold;

    @screen md {
      @apply text-7xl;
    }
  }

  h2 {
    @apply text-4xl font-bold;

    @screen md {
      @apply text-5xl;
    }
  }

  h3 {
    @apply text-3xl;
    line-height: 2.7rem;

    @screen md {
      @apply text-4xl;
    }
  }

  h4 {
    @apply text-2xl font-bold;
  }

  h5 {
    @apply text-xl;
  }

  h6 {
    @apply text-lg;
  }
}

.columns-2 {
  columns: 2;

  @screen md-max {
    columns: 1;
  }
}

.head-shadow {
  -webkit-box-shadow: 0px 10px 20px 0px rgb(0 0 0 / 0.3);
  -moz-box-shadow: 0px 10px 20px 0px rgb(0 0 0 / 0.3);
  box-shadow: 0px 10px 20px 0px rgb(0 0 0 / 0.3);
}
.padding-y-20 {
  padding-top: 25%;
  padding-bottom: 25%;
}

.job {
  &__cover {
    > div {
      display: block !important;
    }
  }
}

@layer utilities {
  .placeholder-font-medium::placeholder {
    @apply font-medium;
  }
}

.grd::after {
  content: '';
  flex: auto;
  max-width: calc(100% / 3);
}
.h-38 {
  height: 9.5rem;
}
.modal-naked {
  padding: 0;
  margin: 0;
  border-radius: 0;
  overflow: hidden;
}
.modal-body-split {
  position: relative;
  width: 50%;
  min-height: 334px;
  float: left;
  padding: 30px 30px;
  color: black;
  background: white;
  @screen xxs {
    width: 100%;
  }
  @screen md {
    width: 50%;
  }
}
.modal-body-split-header {
  margin-bottom: 1em;
  font-weight: 600;
}
.modal-body-login {
  background: #38fddb;
  color: black;
  z-index: 2;
  box-shadow: 6px 0px 14px -1px rgba(103, 103, 106, 0.22);
}
.modal-body-signup {
  color: black;
}

@import './lists.scss';
@import './select.scss';
@import './helpers.scss';
@import './buttons.scss';
@import './content.scss';
