.list {
  &--check {
    ul {
      list-style: none !important;
      padding-left: 0 !important;
    }

    li {
      position: relative;
      padding-left: 35px;
      margin-bottom: 8px;

      @screen md {
        margin-bottom: 16px;
      }

      .check {
        position: relative;
        width: 18px;
        height: 18px;
        top: 4px;
        @apply absolute left-0 top-0 rounded-full flex items-center justify-center;

        &::after {
          content: ' ';
          @apply border-l-2 border-b-2 border-white absolute transform -rotate-45;
          left: 5px;
          top: 6px;
          width: 8px;
          height: 5px;
        }
      }

      &::before {
        content: '';
        @apply rounded-full bg-purple flex items-center justify-center absolute left-0 top-2;
        width: 18px;
        height: 18px;
        top: 2px;
      }

      &::after {
        content: '';
        @apply border-l-2 border-b-2 border-white absolute transform -rotate-45;
        left: 5px;
        top: 8px;
        width: 8px;
        height: 5px;
        border-color: white;
        // border-color: var(--border-color);
      }

      &.no-default {
        &::before,
        &::after {
          display: none;
        }
      }
    }

    &-big {
      li {
        margin-bottom: 16px;

        &::after {
          width: 16px;
          height: 6px;
          top: 6px;
        }

        @screen md {
          margin-bottom: 20px;

          &::after {
            width: 17px;
            height: 7px;
            top: 9px;
          }
        }

        @screen xl {
          padding-left: 40px;
          margin-bottom: 30px;

          &::after {
            width: 19px;
            height: 9px;
            top: 11px;
          }
        }
      }
    }
  }
}
