.image-fit-container {
  @apply relative h-full w-full;

  &--big {
    @screen md-max {
      height: 212px;
    }
  }
}

.image-fit {
  @apply absolute object-cover left-1/2;

  transform: translateX(-50%);
  max-height: 100%;
  max-width: 500px;
  min-width: 100%;

  @screen md {
    @apply relative;
    max-width: 100%;
    height: 100%;
  }
}

.embed-container {
  @apply relative h-0 overflow-hidden;
  padding-bottom: 56.25%;
  max-width: 100%;
  iframe,
  object,
  embed {
    @apply absolute top-0 left-0 w-full h-full;
  }
}

.top-nav {
  span {
    @apply bg-purple text-white rounded-full px-2 py-1 font-normal;
  }
}

.top-tech-mark {
  @apply absolute bottom-0 right-0 w-28 h-28 rounded-br-lg overflow-hidden flex justify-end items-end;

  span {
    @apply relative z-10;
  }

  &:before {
    content: '';
    @apply w-0 h-0 border-solid absolute;
    border-width: 0 0 5rem 5rem;
    border-color: transparent transparent #38fddb transparent;

    @screen md {
      border-width: 0 0 7rem 7rem;
    }
  }
}

.cp-nav {
  background-color: rgba(1, 1, 1, 0.5);
  padding-top: 10px;
  @screen md-max {
    background-color: transparent;
  }
}

.circle-icon {
  display: flex;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  text-align: center;
  line-height: 100px;
  vertical-align: middle;
  padding: 10px;
}

.recruitment-steps {
  scroll-snap-type: x mandatory;
  .step {
    flex-grow: 1;
    flex-basis: 0;
    position: relative;
    min-width: 250px;
    scroll-snap-align: center;

    @screen lg {
      text-align: center;
      &:after {
        content: ' ';
        height: 1px;
        background-color: #ccc;
        width: calc(100% - 60px);
        margin: 0 30px;
        position: absolute;
        top: 10px;
        left: 50%;
      }
      &:last-child:after {
        display: none;
      }
    }
    .check {
      position: relative;
      width: 18px;
      height: 18px;
      top: 4px;
      @apply block rounded-full flex items-center justify-center;

      &::after {
        content: '';
        @apply border-l-2 border-b-2 border-white absolute transform -rotate-45;
        left: 5px;
        top: 6px;
        width: 8px;
        height: 5px;
        display: none;

        @screen lg {
          display: block;
        }
      }
    }
  }
  .fa {
    color: white;
    font-size: 20px;
  }
  span {
    font-weight: 400;
    margin-bottom: 30px;
    @screen lg {
      max-width: 250px;
    }
  }
}
.recruitment-length {
  display: inline-block;
  color: #38fddb;
}

.person-photo {
  flex-shrink: 0;
  margin-right: 20px;
}
