.languageChanger {
  button {
    color: #7e868f;
    transition: 0.3s;

    &:hover {
      color: #ec602b;
    }
  }
}
