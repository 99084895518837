.button {
  &-primary {
    @apply bg-primary text-white py-4 px-8 rounded-full transition-all duration-150;
    @apply font-bold;
    @apply text-md;
    @apply hover:bg-primary-hover;
  }

  &-secondary {
    @apply bg-white text-black py-4 px-8 rounded-full border-1 border-gray-dark transition-all duration-150;
    @apply font-bold;
    @apply text-md;
    @apply hover:bg-gray-50;
  }

  &-white-clear {
    @apply bg-transparent text-white py-4 px-8 rounded-full border-1 border-white transition-all duration-150;
    @apply font-bold;
    @apply text-md;
    @apply hover:bg-gray-50 hover:bg-opacity-10;
  }

  &-black-clear {
    @apply bg-transparent text-black py-4 px-8 rounded-full border-1 border-neutral-500 transition-all duration-150;
    @apply font-bold;
    @apply text-md;
    @apply hover:bg-white hover:bg-opacity-50;
  }

  &-rounded {
    @apply p-4;
  }
}
